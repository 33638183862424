<template>
  <div>
    <transition-group name="fade" mode="out-in">
      <div v-if="!loaded" class="page__loading" key="loading">
        <AppLoading />
      </div>
      <div class="error-content error-404" key="content">
        <div>
          <h2>{{ $t("header404") }}</h2>
          <h3>{{ $t("header404Second") }}</h3>
        </div>
        <img src="/img/error404.png" alt="Error 404" class="error-background" />
        <div>
          <h5>{{ $t("content404") }}</h5>
          <button
            class="btn btn-md btn-primary-light error-button"
            @click.prevent="goToLandingPage"
          >
            {{ $t(profile ? "goBack" : "goLanding") }}
          </button>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AppLoading from '../components/AppLoading';

export default {
  name: 'error404',
  i18nOptions: { namespaces: 'errors' },
  components: {
    AppLoading,
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    ...mapState({
      profile: state => state.profile.profile,
    }),
  },
  mounted() {
    this.loaded = true;
    this.$store.dispatch('profile/getProfile');
  },
  methods: {
    goToLandingPage() {
      // eslint-disable-next-line no-nested-ternary
      this.$router.push(this.profile === null ? '/'
        : this.profile.role.name === 'neo-client' || this.profile.role.name === 'neo-client-hr' ? '/companies-searches' : '/search');
    },
  },
};
</script>
